/*
File Name: 		booked.css
Written by: 	Dan Fisher - (https://themeforest.net/user/dan_fisher)
Description:  Styling for Booked (including form, profile, calendar etc.)
*/

// Basic Imports
@import "config/imports";

// Booked
@import "booked/booked-icons";
@import "booked/booked-animations";
@import "booked/booked-style";
@import "booked/booked-responsive";
@import "booked/booked-color-theme";
